import { IRequest } from '@dltru/dfa-models'

import * as adapter from './adapter'
import * as app from './app'
import * as auth from './auth'
import * as blacklistProfile from './blacklistProfile'
import * as blocking from './bloking'
import * as calcFee from './calculateFee'
import * as calendar from './calendar'
import * as certificates from './certificates'
import * as clients from './client'
import * as deals from './deals'
import * as dfa from './dfa'
import * as documents from './documents'
import * as earlyRepayment from './earlyRepayment'
import * as employee from './employee'
import * as externalRates from './externalRates'
import * as locks from './locks'
import * as money from './money'
import * as orders from './orders'
import * as owners from './owners'
import * as paymentScheduler from './paymentsScheduler'
import * as profiles from './profiles'
import * as qualified from './qualified'
import * as reports from './reports'
import * as roleForms from './roleForms'
import * as secondaryDfa from './secondaryDfa'
import * as spvbOrder from './spvb-order'
import * as tariff from './tariff'
import * as tax from './tax'
import * as transfer from './transfer'
import * as users from './users'
import * as wallet from './wallet'

export async function requestDecorator<T>(
    url: string,
    method: string,
    successCode: number,
    options: any,
): Promise<IRequest<T>> {
    try {
        const { data: responseData, status } = (await this.requestAgent({
            method,
            url,
            ...options,
        })) as { data: T; status: number }

        if (status !== successCode) {
            throw new Error('response error')
        }

        return { data: responseData, error: '' }
    } catch (error: ReturnType<Error>) {
        return { error }
    }
}

const Services = {
    ...app,
    ...dfa,
    ...auth,
    ...money,
    ...orders,
    ...profiles,
    ...transfer,
    ...qualified,
    ...roleForms,
    ...secondaryDfa,
    ...deals,
    ...locks,
    ...wallet,
    ...owners,
    ...clients,
    ...users,
    ...blocking,
    ...certificates,
    ...adapter,
    ...calcFee,
    ...employee,
    ...documents,
    ...externalRates,
    ...blacklistProfile,
    ...tariff,
    ...calendar,
    ...paymentScheduler,
    ...earlyRepayment,
    ...tax,
    ...reports,
    ...spvbOrder,
}

export default Services
export type ServicesType = typeof Services
